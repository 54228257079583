.header {
  @apply bg-gradient-to-r from-primary to-secondary-100;

  &::before {
    @apply absolute bottom-0 left-0 w-0 h-0 content-[''] border-b-white border-b-[80px]  md:border-b-[150px];

    border-bottom-style: solid;
    border-right: 100vw solid transparent;
  }
}
