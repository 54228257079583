.reference {
  position: relative;

  &::before {
    content: url('https://res.cloudinary.com/dau5eft48/image/upload/t_icon-template/v1710260510/thumbnail_5_f3dcc120ee.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
  }

  img {
    max-height: 82px;
    object-position: left center;
    object-fit: contain;
  }
}
